import WebServiceRequest from "./WebServiceRequest";

class FakeUserGetReportRequest extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("FakeUser/GetReport");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    FakeUserGetReportRequest,
}
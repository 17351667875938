<template>
  <page-wrapper
      :isLoading="isLoading"
      :hasFilters="true"
      @submitFilters="getReports"
  >
    <template #title>Fake users reports (Total Credits : {{ totalCredit }} Tron)</template>
    <template #titleBtn>
      <b-button
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          class=""
          variant="success"
          @click="renderCreateModal"
      >
        + Add fake users
      </b-button>
    </template>
    <template #filters>
      <custom-date-picker
          @getSelectedDate="setFromDateFilter"
          inputLabel="From Date"
          inputPlaceholder="2023-12-06"
          inputId="fromDate"
      ></custom-date-picker>
      <custom-date-picker
          @getSelectedDate="setToDateFilter"
          inputLabel="To Date"
          inputPlaceholder="2023-12-06"
          inputId="toDate"
      ></custom-date-picker>
      <search-input
          v-model.trim="requestsListFilters.groupId"
          inputId="groupId"
          inputLabel="Search By group id"
          inputPlaceholder=""
      ></search-input>
    </template>
    <custom-table
        v-if="requestsList"
        customIcon="CheckSquareIcon"
        customIconClass="text-success"
        :pageNumber="requestsListFilters.pageNumber"
        :count="requestsListFilters.count"
        :totalCount="totalCount"
        :tableItems="requestsList"
        :tableColumns="myTableColumns"
        @changePage="changePage"
    >
    </custom-table>

    <create-new-fake-users-modal
        :isLoading="isLoading"
        :okCallback="createNewFakeUsers"
    ></create-new-fake-users-modal>
  </page-wrapper>
</template>

<script>
import {FakeUserGetReportRequest} from "@/libs/Api/FakeUsers";

export default {
  title: "Fake users reports",
  mounted() {
    this.getReports();
  },
  data() {
    return {
      isLoading: false,
      totalCredit:'-',
      requestsListFilters: {
        pageNumber: 1,
        count: 10,
        fromDate:'',
        toDate:'',
        groupId:''
      },
      totalCount: null,
      requestsList: null,
      myTableColumns: [
        {
          key: "groupId",
          label: "group id",
        },
        {
          key: "userName",
          label: "userName",
          formatter: (value) => value.trim() || "-",
        },
        {
          key: "createDate",
          label: "Request Create Date",
          formatter: (value) => new Date(value).toLocaleDateString("en-US"),
        },
        {
          key: "walletCredit",
          label: "Wallet Credit",
          formatter: (value) => {
            let priceValue = 0;
            if (value && +value > 0) {
              priceValue = value;
            }
            return `${priceValue} $`;
          },
        },
      ],
      tempRequest: null,
    };
  },
  methods: {
    async getReports() {
      try {
        this.isLoading = true;
        let _this = this;
        let fakeUserGetReportRequest = new FakeUserGetReportRequest(_this);
        fakeUserGetReportRequest.setParams(
            _this.requestsListFilters
        );
        await fakeUserGetReportRequest.fetch((res) => {
          _this.totalCount = res.data.totalCount;
          _this.totalCredit = res.data.totalCredit;
          _this.requestsList = res.data.data;
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async createNewFakeUsers(param) {
      try {
        this.isLoading = true;
        let _this = this;
        let accountCreateFakeUser = new AccountCreateFakeUser(_this);
        accountCreateFakeUser.setRequestParam({
          count: param.count,
          countryId: param.countryId,
        });
        await accountCreateFakeUser.fetch((res) => {
          if (res.isSuccess) {
            _this.$bvModal.hide("create-new-fake-users");
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Confirmed!",
                icon: "CheckIcon",
                variant: "success",
                text: "Operation successful",
              },
            });
            _this.getReports();
          } else {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Failed",
                icon: "CloseIcon",
                variant: "danger",
                text: res.errorMessage || "Something went wrong.",
              },
            });
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setToDateFilter(date) {
      this.requestsListFilters.toDate = date;
    },
    setFromDateFilter(date) {
      this.requestsListFilters.fromDate = date;
    },
    renderCreateModal(){
      this.$bvModal.show("create-new-fake-users");
    },
    changePage(pageNumber) {
      this.requestsListFilters.pageNumber = pageNumber;
      this.getReports();
    },
  },
  components: {
    CreateNewFakeUsersModal,
    CreateNewBonusModal,
    PageWrapper,
    CustomDatePicker,
    CashoutConfirmationModal,
    SearchInput,
    CustomTable,
    BButton,
    BBadge,
  },
};
import PageWrapper from "@/views/components/Utilities/PageWrapper.vue";
import CustomDatePicker from "@/views/components/Utilities/CustomDatePicker.vue";
import SearchInput from "@/views/components/Utilities/SearchInput.vue";
import CustomTable from "@/views/components/Utilities/CustomTable.vue";
import CashoutConfirmationModal from "@/views/components/Referral/CashoutConfirmationModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { BButton, BBadge } from "bootstrap-vue";
import CreateNewBonusModal from "@/views/components/Bonus/CreateNewBonusModal.vue";
import CreateNewFakeUsersModal from "@/views/components/User/CreateNewFakeUsersModal.vue";
import {AccountCreateFakeUser} from "@/libs/Api/Account";
</script>
